<template>
  <div class='landlords-connection'>
    <div class="container">
      <h3>{{$t('contactsTitle')}}</h3>
      <span>{{$t('contactsContent')}}</span>
      <a href="tel:7766">
        <p>{{$t('landlordOnCallPhone1')}}</p>
        <p>{{$t('landlordOnCallPhone2')}}</p>
      </a>
      <a href="mailto:arenda@magnum.kz" class="color-pink">{{$t('landlordOnCallEmail')}}</a>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'LandlordsConnection'
  }
</script>
